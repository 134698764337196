<template>
  <div class="shopMain">
    <div class="c h padding-10" v-if="showOpt.showSeller">
      <div class="no-flex">供应商</div>
      <div class="flex"></div>
      <div>
        <quick-select v-model="query.sellerId" filterable url="api/purchase/seller" value-field="belongTo" display-field="belongToName" auto-select-first-option style="width: 150px" />
      </div>
    </div>
    <div class="c h padding-10" @click="drawer.shop = true" v-if="showOpt.showShop">
      <div class="no-flex">直营门店</div>
      <div class="flex ta-r padding-05"></div>
      <div>
        <span>{{ store.shopTitle }}</span>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="c h padding-10" v-if="showOpt.showDimension">
      <div class="no-flex">分析维度</div>
      <div class="flex"></div>
      <el-radio-group v-model="query.dateGroup" size="mini">
        <el-radio label="year">年</el-radio>
        <el-radio label="month">月</el-radio>
        <el-radio label="day">日</el-radio>
      </el-radio-group>
    </div>
    <div class="c h padding-10">
      <div class="no-flex">时间范围</div>
      <div class="flex"></div>
      <div class="c h">
        <div class="h c" key="range-year" v-if="query.dateGroup === 'year'">
          <el-date-picker v-model="query.begDate" size="mini" type="year" value-format="timestamp" placeholder="开始年" :clearable="false" style="width: 130px" />
          <div class="padding-0-05">至</div>
          <el-date-picker v-model="query.endDate" size="mini" type="year" value-format="timestamp" placeholder="结束年" :clearable="false" style="width: 130px" />
        </div>
        <div class="h c" key="range-month" v-else-if="query.dateGroup === 'month'">
          <el-date-picker v-model="query.begDate" size="mini" type="month" value-format="timestamp" placeholder="开始月" :clearable="false" style="width: 130px" />
          <div class="padding-0-05">至</div>
          <el-date-picker v-model="query.endDate" size="mini" type="month" value-format="timestamp" placeholder="结束月" :clearable="false" style="width: 130px" />
        </div>
        <div class="h c" key="range-date" v-else-if="query.dateGroup === 'day'">
          <el-date-picker v-model="query.begDate" size="mini" type="date" value-format="timestamp" placeholder="开始日" :clearable="false" style="width: 130px" />
          <div class="padding-0-05">至</div>
          <el-date-picker v-model="query.endDate" size="mini" type="date" value-format="timestamp" placeholder="结束日" :clearable="false" style="width: 130px" />
        </div>
      </div>
    </div>
    <div class="c h padding-10" v-if="showOpt.showYearonyear">
      <div class="no-flex">开启同比</div>
      <div class="flex ta-r padding-0-05">
        <el-date-picker v-model="query.compareYear" type="year" placeholder="选择年" size="mini" style="width: 100px" :clearable="false" v-if="query.yearOnYearShow" />
      </div>
      <el-switch v-model="query.yearOnYearShow" size="mini" />
    </div>
    <div class="c h padding-10" v-if="showOpt.showMonth">
      <div class="no-flex">开启环比</div>
      <div class="flex"></div>
      <el-switch v-model="query.compareMonth" size="mini" />
    </div>
    <div class="c h padding-10" v-if="query.dateGroup !== 'year' && showOpt.showCompareyear">
      <div class="no-flex">同比年度</div>
      <div class="flex"></div>
      <div>
        <el-input-number v-model="query.compareYear" :min="2000" :max="2099" :step="1" :precision="0" controls-position="right" placeholder="输入年份" size="mini" style="width: 120px" />
      </div>
    </div>
    <!-- 商品范围 -->
    <template v-if="showOpt.showRangGoods">
      <div class="c h padding-05" @click="drawer.brand = true">
        <div class="no-flex padding-05">商品品牌</div>
        <div class="flex ta-r padding-05">{{ label.brand }}</div>
        <i class="el-icon-arrow-right padding-05"></i>
      </div>
      <div class="c h padding-05" @click="drawer.series = true" v-if="query.brandId">
        <div class="no-flex padding-05">商品系列</div>
        <div class="flex ta-r padding-05">{{ label.series }}</div>
        <i class="el-icon-arrow-right padding-05"></i>
      </div>
      <div class="c h padding-05" @click="drawer.category = true" v-if="showOpt.showGoodsType">
        <div class="no-flex padding-05">商品分类</div>
        <div class="flex ta-r padding-05">{{ label.category }}</div>
        <i class="el-icon-arrow-right padding-05"></i>
      </div>
    </template>

    <div class="c h padding-05" @click="drawer.goods = true" v-if="showOpt.showCommodity">
      <div class="no-flex padding-05">指定商品</div>
      <div class="flex ta-r padding-05">{{ label.goods }}</div>
      <i class="el-icon-arrow-right padding-05"></i>
    </div>
    <div class="c h padding-10" v-if="showOpt.showRank">
      <div class="no-flex">排名对象</div>
      <div class="flex"></div>
      <div>
        <el-radio-group v-model="query.shopAreaGroup" size="mini">
          <el-radio-button label="area">区域</el-radio-button>
          <el-radio-button label="shop">门店</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="padding-10" v-if="showOpt.showLevel">
      <div class="no-flex">汇总级别</div>
      <div class="padding-10">
        <el-radio-group v-model="query.goodsGroup" size="mini">
          <el-radio-button label="brandId">品牌结构</el-radio-button>
          <el-radio-button label="seriesId">系列结构</el-radio-button>
          <el-radio-button label="goodsCategoryId">分类结构</el-radio-button>
          <el-radio-button label="spuId">商品结构</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="c h padding-10" v-if="showOpt.showReported">
      <div class="no-flex">仅显示已上报</div>
      <div class="flex"></div>
      <el-switch v-model="query.reportOnly" @change="handleReportOnlyChange" size="mini" />
    </div>
    <div class="padding-10" v-if="showOpt.showRankGist">
      <div class="no-flex">排名依据</div>
      <div class="padding-10">
        <el-radio-group v-model="query.saleValueGroup" size="mini">
          <el-radio-button label="saleNumber">销售数量</el-radio-button>
          <el-radio-button label="realSumMoney">销售金额</el-radio-button>
          <el-radio-button label="sendNumber">发货数量</el-radio-button>
          <el-radio-button label="sendMoney">发货金额</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <!-- 选直营门店 -->
    <shop-list :visible.sync="drawer.shop" v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="handleShopChange" @init="handleInited" />
    <!-- 选指定商品 -->
    <sku-selector request-url="api/goods/sku" :visible.sync="drawer.goods" @change="handleGoodsSelect" />
    <!-- 商品范围 -->
    <div class="c h padding-10">
      <brand-selector v-model="query.brandId" :label.sync="label.brand" :visible.sync="drawer.brand" @change="handleBrandSelect" />
      <series-selector v-model="query.seriesId" :label.sync="label.series" :brand-id="query.brandId" :visible.sync="drawer.series" />
      <category-selector key="cate1" v-model="query.categoryId" :label.sync="label.category" :visible.sync="drawer.category" />
    </div>
  </div>
</template>

<script>
import shopList from "../shopList";
import skuSelector from "../selector/skuSelect";
import brandSelector from "../selector/brandSelect";
import seriesSelector from "../selector/seriesSelect";
import categorySelector from "../selector/categorySelect";
export default {
  props: {
    showOpt: Object,
    query: Object,
  },
  components: {
    shopList,
    skuSelector,
    brandSelector,
    seriesSelector,
    categorySelector,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      label: {
        brand: "全部",
        series: "全部",
        category: "全部",
        categoryB: "全部",
        goods: "全部",
      },
      goods: {
        brandName: null,
        seriesName: null,
        categoryName: null,
      },
      drawer: {
        shop: false,
        goods: false,
        brand: false,
        series: false,
        category: false,
      },
      store: {
        shopTitle: "全部",
      },
    };
  },
  methods: {
    handleInited() {
      this.$emit("init");
    },
    handleShopChange(data) {
      this.store.shopTitle = data ? data.name : "全部";
    },
    handleGoodsSelect(res) {
      this.query.goods = res;
      if (res && res.length) {
        this.label.goods = res.map((o) => o.name).join("、");
      } else {
        this.label.goods = "全部";
      }
    },
    handleBrandSelect(res) {
      if (this.query.hasOwnProperty("seriesId")) {
        this.query.seriesId = null;
        this.label.series = "全部";
      }
    },
    handleReportOnlyChange(val) {
      if (this.showOpt.showRankGist) {
        if (val == true) {
          this.query.saleValueGroup = "realCheckSumMoney";
        } else {
          this.query.saleValueGroup = "realSumMoney";
        }
      }
    },
  },
};
</script>